










































import {Component, Vue} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {email, maxLength, numeric, required} from 'vuelidate/lib/validators';
import {vxm} from '@/store';
import BhInput from '@/components/BhInput.vue';
import Loader from '@/components/Loader.vue';
import BhTextarea from '@/components/BhTextarea.vue';
import maxNameLength from '@/constants/maxNameLength';
import clickInside from '@/utils/clickInside';

Vue.use(Vuelidate);

@Component({
  name: 'JoinOurList',
  components: {BhTextarea, BhInput, Loader},
  directives: {
    clickInside,
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(maxNameLength),
      },
      email: {
        email,
        required,
      },
      phone: {
        numeric,
        maxLength: maxLength(15),
      },
      comment: {
        maxLength: maxLength(200),
      },
    },
  },
})
export default class JoinOurList extends Vue {
  loading = false;
  form = {
    name: '',
    email: '',
    phone: '',
    comment: '',
  };
  sent = false;

  get nameErrorMessage(): string {
    return !this.$v.form.name?.required
      ? 'Name is a required field'
      : `Name should be up to ${this.$v.form.name?.$params.maxLength.max} symbols long`;
  }
  get commentErrorMessage(): string {
    return `Comment length is limited to ${this.$v.form.comment?.$params.maxLength.max} symbols`;
  }
  get phoneErrorMessage(): string {
    return `Please enter up to ${this.$v.form.phone?.$params.maxLength.max} digits`;
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.loading = true;
    vxm.user
      .requestJoin(this.getParams())
      .then(() => {
        this.sent = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  getParams(): {name: string; email: string; phone?: string; comment?: string} {
    return {
      name: this.form.name,
      email: this.form.email,
      ...(this.form.phone && {phone: this.form.phone}),
      ...(this.form.comment && {comment: this.form.comment}),
    };
  }
  closeModal(): void {
    this.$emit('closeModal');
  }
}
