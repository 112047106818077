












































import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Vuelidate from 'vuelidate';
import {minLength, required, email} from 'vuelidate/lib/validators';
import BhInput from '@/components/BhInput.vue';
import JoinOurList from '@/components/modals/JoinOurList.vue';
import AuthErrors from '@/constants/authErrors';
import AuthErrorInterface from '@/types/AuthErrorInterface';

Vue.use(Vuelidate);

@Component({
  components: {
    BhInput,
    JoinOurList,
  },
  validations: {
    form: {
      email: {required, email},
      password: {required, minLength: minLength(8)},
    },
  },
})
export default class Login extends Vue {
  form = {
    email: '',
    password: '',
  };
  logInError = false;
  logInErrorMessage = '';
  showJoinModal = false;
  showPassword = false;

  get passwordErrorMessage(): string {
    return !this.$v.form.password?.required
      ? 'Password is required field'
      : `Password should be at least ${this.$v.form.password?.$params.minLength.min} symbols long.`;
  }

  logIn(): void {
    this.logInError = false;
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    vxm.user
      .signIn(this.form)
      .then(() => {
        this.$toasted.show('Logged in successfully.', {
          className: 'toasted-info',
        });
        this.$socket.io.opts.query.token = vxm.user.accessToken;
        this.$socket.connect();
        this.$router.replace({name: 'home'});
      })
      .catch((error: AuthErrorInterface) => {
        this.logInErrorMessage = AuthErrors[error.response.data.message];
        this.logInError = true;
      });
  }
}
